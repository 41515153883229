import validator from "validator";

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onabort = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const CheckIfPasswordIsValid = (
  oldPassword,
  password,
  confirmPassword
) => {
  if (oldPassword.length === 0) {
    return "Old Password is Required";
  } else if (password.length === 0) {
    return "New Password is Required";
  } else if (confirmPassword.length === 0) {
    return "Confirm Password is Required";
  }
  if (password !== confirmPassword) {
    return "Confirm password not matched!";
  }
  if (
    !validator.isStrongPassword(password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    })
  ) {
    return "Password should be Strong";
  }
  if (password.length < 8) {
    return "New password is too long!";
  }
  if (oldPassword === password) {
    return "New Password can't be same as old password";
  }
  return false;
};

// export const CheckIfPasswordIsValid = (
//   oldPassword,
//   password,
//   confirmPassword
// ) => {
//   console.log("CheckIfPasswordIsValid")
//   if (password !== confirmPassword) {
//     return "Confirm password not matched!";
//   }
//   if (password.length < 8) {
//     return "New password is too Long!";
//   }
//   if(oldPassword===password){
//     return "New Password is same as old password";
//   }
//   return false;
// };

export const checkSpecialCharater = (string) => {
  const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+/;
  if (format.test(string)) {
    return true;
  } else return false;
};

export const timeConverter = (n) => {
  const num = n;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return rhours
    ? rhours + " hour(s) and " + +rminutes + " minute(s)"
    : "" + rminutes + " minute(s)";
};

export const durationTimetoMinutes = (d) => {
  const hours = Number(d.split(":")[0]);
  const minutes = Number(d.split(":")[1]);
  const seconds = parseFloat((d.split(":")[2] / 60).toFixed(2));

  return hours * 60 + minutes + seconds;
};
