import React, { useState, useEffect } from "react";
import useStyles from "../assests/css/styles";
import { Header } from "./Header";
import { Box, CircularProgress, Grid, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { getDeviceAndQueueList } from "../api";
import { ReactSVG } from "react-svg";
import deviceNotFound from "../assests/icon/Device-not-found.svg";
import { ReactComponent as DeviceIcon } from "../assests/images/DeviceIcon.svg";
import { useHistory } from "react-router-dom";
import { getUserInfo } from "../services/getUserInfo";

const VoiceMessage = (props) => {
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false);
    const [deviceList, setDeviceList] = useState([]);
    const [deviceName, setDeviceName] = useState('');
    const history = useHistory()

    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = async () => {
        const details = await getUserInfo();
        const userName = details.email && details.email.split("@")[0];
        setDeviceName(userName)
        fetchDeviceAndQueueList(userName);
    };

    const fetchDeviceAndQueueList = (user) => {
        setIsLoading(true);
        getDeviceAndQueueList(user)
            .then((res) => {
                setIsLoading(false);
                setDeviceList(res);
            })
            .catch((err) => {
                setIsLoading(false);
                // console.log(err.message);s
            });
    };

    const handleListClick = (queue, device) => {
        const idHash = `${deviceName}_${device}_${queue.replace('.json', "").replace('.json', "")}`
        history.push(`/message-details/${btoa(idHash)}`)
    };


    return (
        <div className={classes.main}>
            <Header />
            {!isLoading ? (
                <div style={{ margin: "12px" }}>
                    {deviceList && deviceList.length ? (
                        <Grid container spacing={2}>
                            {
                                deviceList.map((list, index) => {
                                    const device = list.split("_")[1];
                                    const queue = list.split("_")[2];
                                    return (
                                        <Grid item xs={12} md={6} lg={4} sm={6}>
                                            <ListItem
                                                className={classes.listName}
                                                component="div"
                                                key={index}
                                            >
                                                <ListItemIcon
                                                    onClick={() => handleListClick(queue, device)}
                                                    style={{ borderRight: "1px solid #D8D8D8" }}
                                                >
                                                    <DeviceIcon color="primary" style={{ height: '90px', marginRight: "20px" }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    onClick={() => handleListClick(queue, device)}
                                                    primary={queue.replace(".json", "")}
                                                />
                                                {/* <ListItemIcon onClick={() => handleListClick(queue, device)}>
                              <ChevronRightIcon color="primary" />
                            </ListItemIcon> */}
                                            </ListItem>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    ) : (
                        <Box className={classes.noDevice}>
                            <ReactSVG src={deviceNotFound} />
                            <p>Queue not found.</p>
                        </Box>
                    )}
                </div>
            ) : (
                <CircularProgress className={classes.loader} color="primary" />
            )}
        </div>
    );
};

export default VoiceMessage;
