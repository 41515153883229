import React, { useState, useEffect } from "react";
import useStyles from "../assests/css/styles";
import {
  CircularProgress,
  ListItem,
  ListItemText,
  Box,
  ListItemIcon,
  Grid,
} from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { checkDeviceIP, getDeviceAndQueueList } from "../api";
import { getUserInfo } from "../services/getUserInfo";
import { ReactSVG } from "react-svg";
import deviceNotFound from "../assests/icon/Device-not-found.svg";
import qrScan from "../assests/icon/qr.png";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import { CModal } from ".";
import { Button } from "@aws-amplify/ui-react";
import { Header } from "../component/analytics/Header";

const BillBoardScreen = (props) => {
  const history = useHistory();

  const classes = useStyles();

  const [checkIPResponse, setCheckIPResponse] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const handleClose = () => history.push("/");
  return (
    <div className={classes.main}>
      <Header />
      {/* <div style={{textAlign:"center"}}>
        <p>Goto settings and Please connect to WIFI</p>
        <p>WiFi : BillBoard_QMD</p>
        <p>Password: 87654321</p>
      </div> */}
      <div style={{ marginTop: "110px" }}>
        <CModal open={Boolean(true)} handleClose={handleClose}>
          <div style={{ marginTop: "46px" }}>
            <Box>
              <img src={qrScan} alt="QR code" />
            </Box>
            <p style={{ fontSize: 18 }}>Go to settings and Please connect to</p>
            <p style={{ fontSize: 18 }}>WiFi : BillBoard_QMD</p>
            <p style={{ fontSize: 18 }}>Password: 87654321</p>
            <Button
              onClick={() => history.push("/")}
              style={{
                backgroundColor: "#060543",
                color: "white",
                marginTop: "22px",
              }}
            >
              Go to Home
            </Button>
          </div>
        </CModal>
      </div>
    </div>
  );
};

export default BillBoardScreen;
