import React, { useState, useEffect } from "react";
import useStylesMain from "../assests/css/styles";
import useStyles from "../assests/css/styles";
import "../assests/css/style.css";
import AuthDisplay from "../AuthDisplay";
import { CContext } from "../component/auth-components/CContext";
// import { uploadImageName } from "../api";
import ProfileSetup from "./ProfileSetup";
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Fab,
  Grid,
  ListItem,
  ListItemText,
  TextField,
  Box,
  ListItemIcon,
  Modal,
  Typography,
  Input,
  IconButton,
  FormControlLabel,
  Checkbox,
  Drawer,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getUserInfo } from "../services/getUserInfo";
import { ReactSVG } from "react-svg";
import queueNotFound from "../assests/icon/Queue-not-found.svg";
import Logo from "../assests/images/habilelabs_full_logo.svg";
import dummyImage from "../assests/images/avatar.jpg";
import { CModal } from "../../src/component/";
import { PhotoCamera } from "@material-ui/icons";
import { checkSpecialCharater, getBase64 } from "../services/utils";
import { VisualViewport } from "../component/";
import { Controller, useForm } from "react-hook-form";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LoadingSpinner from "../component/auth-components/LoadingSpinner";
import { sendUrlToBackendHandler } from "../../src/api";
import { ReactComponent as DeviceIcon } from "../assests/images/DeviceIcon.svg";
import Home from "../pages/Home";
import {
  createQueue,
  getCurrentCount,
  getDeviceAndQueueList,
  getTotalCount,
  getUserImage,
  uploadImageName,
} from "../../src/api";
import AddIcon from "@material-ui/icons/Add";
import qrScan from "../assests/icon/qr.png";
import useStyles1 from "../assests/css/profileStyle";
import MenuIcon from "@mui/icons-material/Menu";
import { Header } from "../../src/component/analytics/Header";

const MainLayout = (props) => {
  const history = useHistory();
  const profileClasses = useStyles1();

  const { authState, signOut } = props;
  const { userDetailThroughProp } = props;
  // console.log("LoginDetails", userDetailThroughProp);
  const classesMainLayout = useStylesMain();
  const [userDetailProp, setuserDetailProp] = useState(
    props.userDetailThroughProp
  );
  const { isModalOpen, setisModalOpen } = React.useContext(CContext);
  const [bottomTabValue, setBottomTabValue] = useState(0);
  const [userName, setUserName] = React.useState("");
  const [nameContext, setnameContext] = useState("");
  const [showImageError, setImageShowError] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState(null);
  const [imageUpload, setImageUpload] = React.useState(false);
  const [img, setImg] = React.useState("");
  const [imageToupload, setimageToupload] = React.useState("");
  const [nameValidationError, setnameValidationError] = useState("");
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [newId, setNewId] = useState("");
  const [token, setToken] = useState("");
  const [addDevice, setAddDevice] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const name = userDetails.email && userDetails.email.split("@")[0];
  const [deviceList, setDeviceList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [currentNumber, setCurrentNumber] = useState(0);
  const [totalCount, setTotalNumber] = useState({ Count: 0 });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleCloseDrawer = () => {
    // if (
    //   event.type === "keydown" &&
    //   (event.key === "Tab" || event.key === "Shift")
    // ) {
    //   return;
    // }
    setOpenDrawer(false);
  };
  const [openImgName, setOpenImgName] = useState(false);
  const handleCloseImgName = () => setOpenImgName(false);
  const [check, setCheck] = useState(true);
  const [showDeviceError, setShowDeviceError] = useState(false);
  const [withDevice, setWithDevice] = useState(false);
  const [image, setImage] = useState(dummyImage);
  const [userNameNew, setUserNameNew] = useState(name);
  const { handleSubmit, control, resetField } = useForm();
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  // const [queueCreationError, setQueueCreationError] = useState("");
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [newValue, setNewValue] = useState(
    localStorage.IsProfileSettingUP &&
      localStorage.IsProfileSettingUP === "false"
  );
  const [isProfileSetup, setisProfileSetup] = useState(false);

  // const name = userNameFromContext && userNameFromContext.split("@")[0];
  const fetchDeviceAndQueueList = (user) => {
    setIsLoading(true);
    getDeviceAndQueueList(user)
      .then((res) => {
        setIsLoading(false);
        setDeviceList(res);
        // console.log(deviceList);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err.message);
      });
  };
  React.useEffect(() => {
    setnameContext(userNameFromContext && userNameFromContext.split("@")[0]);
  }, [userNameFromContext]);
  const { userNameFromContext, setuserNameFromContext } =
    React.useContext(CContext);

  React.useEffect(() => {
    setBottomTabValue(
      Number(localStorage.getItem("BottomNavigationScreenIndex"))
    );
  }, []);

  // React.useEffect(() => {
  //   if (prevState === "confirmSignUp" && authState === "signedIn") {
  //     return;
  //   }
  //   setPrevState(authState);
  // }, [authState]);
 
  useEffect(() => {
    // console.log("alert",alert.message)
    // localStorage.setItem("BottomNavigationScreenIndex", 0);

    getUserDetails();
    if (!deviceList && !deviceList.length) {
      setOpen(true);
    }
  }, [name]);

  // console.log(name, "mainLayoutName");

  // useEffect(() => {
  //   console.log("mainLayoutName", name);
  //   console.log("mainLayoutAuthState", authState);
  //   console.log("mainLayoutAuthState", userDetails);
  //   if (name && authState === "signedIn") {
  //     console.log("mainLayoutName", name);
  //     console.log("mainLayoutAuthState", authState);
  //     console.log("mainLayoutAuthState", userDetails);
  //     getUserEmailUserName();
  //     getUserDetails();
  //   }
  // }, [name, authState]);

  useEffect(() => {
    if (authState === "signedIn") {
      // console.log(
      //   "insidelocalStorage.setItem(false)",
      //   localStorage.getItem("IsProfileSettingUP")
      // );
      setisProfileSetup(localStorage.getItem("IsProfileSettingUP"));
    }
  }, [authState]);

  useEffect(() => {
    if (authState === "signedIn") {
      getUserDetails();
    }
  }, [authState]);

  const toggleDrawer = (event, deviceName) => {
    const deviceToken = btoa(
      `${name.replace(".", "")}_${deviceName.split("_")[1]}_${
        deviceName.split("_")[2]
      }`
    );
    setOpenDrawer(!openDrawer);
    setNewId(deviceName);
    setToken(deviceToken);
    getCurrentCount(deviceName)
      .then((res) => {
        setCurrentNumber(res.currentNum);
      })
      .catch((err) => {
        // console.log(err.message);
      });
    getTotalCount(deviceName)
      .then((res) => {
        setTotalNumber(res);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  const getUserDetails = async () => {
    const details = await getUserInfo();
    setUserDetails(details);
    const userName = details.email && details.email.split("@")[0];
    // console.log(userName, "mainLayoutName");

    fetchDeviceAndQueueList(userName);
  };

  const getUserEmailUserName = () => {
    getUserImage(name).then((res) => {
      if (res.status === 200) {
        const data = res.data && res.data.files.link;
        if (data === "no image") setOpenImgName(true);
        else {
          setUserNameNew(
            data
              .substr(data.lastIndexOf("/") + 1)
              .split(".")[0]
              .split("_")[1]
          );
        }
      }
    });
  };

  const handleAddDevice = () => {
    setAddDevice(true);
  };

  const handleDeviceAdded = () => {
    setAddDevice(false);
  };
  const saveImageAndUserName = () => {
    const userName = name + "_" + userNameNew;
    localStorage["img"] = image;
    uploadImageName(userName, image.split(",")[1]).then((res) => {
      if (res.status === 200) {
        const data = res.data.files.link;
        setImage(data);
        handleCloseImgName();
      }
    });
  };
  const handleUploadImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      getBase64(file).then((base64) => {
        setImage(base64);
        setCheck(!check);
        // handleCloseImgName();
      });
    }
  };
  const handleCheckChange = (event) => {
    setWithDevice(event.target.checked);
  };
  const onSubmit = (input) => {
    // sendUrlToBackendHandler(data)
    setIsLoadingSpinner(true);
    if (name) {
      let data = {
        device: `${name}_${input.deviceName}_${input.queueName}`,
      };
      let deviceName = `${name}_${input.deviceName}_${input.queueName}`;
      // console.log(
      //   "dataDEvice",
      //   `${name}_${input.deviceName}_${input.queueName}`
      // );
      const hashId = btoa(
        `${name.replace(".", "")}_${input.deviceName}_${input.queueName}`
      );
      // console.log("dataDEvice", hashId);
      sendUrlToBackendHandler(deviceName.replace(/[.-]/g, ""), hashId);
      createQueue(data)
        .then((res) => {
          if (res.status == 502) {
            // setQueueCreationError(res.data.files);
            setAlert({ message: res.data.files, type: "error" });
            setTimeout(() => {
              setAlert({ message: "", type: "" });
              // setQueueCreationError("");
            }, 3000);
            setIsLoadingSpinner(false);
          } else {
            resetField("queueName");
            resetField("deviceName");
            withDevice && history.push(`/device/${hashId}`);
            setIsLoading(false);
            setAddDevice(false);
            fetchDeviceAndQueueList(name);
            setIsLoadingSpinner(false);
          }
        })
        .catch((err) => {
          // console.log(err.message);
          setIsLoadingSpinner(false);
        });
    }
  };

  // console.log("openDrawer", openDrawer);
  return authState === "signedIn" && !isModalOpen ? (
    <>
      {/* {console.log("main1")} */}
      {/* {console.log("isModalOpen", isModalOpen)} */}
      {/* {console.log("isModalOpen", newValue)} */}
      {newValue !== undefined && !newValue ? (
        <>
          {/* {console.log("hhiiiiiiiiii")} */}
          {/* {console.log("main2")} */}

          {/* {console.log("isModalOpen", isModalOpen, newValue)} */}

          <ProfileSetup udpateValue={() => setNewValue(true)} />
        </>
      ) : (
        <>
          {/* {console.log("hleleleleleleelleleelelele")} */}
          <div className={classesMainLayout.mainSection}>
            <AuthDisplay />
            <div className={classes.main}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Header  handleDeviceAdded={handleDeviceAdded} />
                  {/* <Box className={classes.rootLogo}>
              <IconButton
                className={!addDevice ? classes.hideButton : ""}
                component="span"
                onClick={() => {
                  setAddDevice(false);
                }}
              >
               
                <ChevronLeftIcon />
              </IconButton>
           
              <img src={Logo} alt="logo" onClick={() => history.push("/")} />
            </Box> */}
                  {addDevice ? (
                    <VisualViewport>
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Box
                          component="form"
                          noValidate
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <Grid container className={classes.addQueue}>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ textAlign: "center" }}
                            >
                              <DeviceIcon
                                color="primary"
                                className={classes.deviceIcon}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <b style={{ fontWeight: 1000 }}>
                                Device Informations
                              </b>
                              <p className={classes.pModel}>
                                Please provide details to identify your device.
                              </p>
                              <div>
                                <Controller
                                  name="queueName"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Queue name cannot be empty",
                                    pattern: {
                                      value: /^[a-z0-9]+$/i,
                                      message:
                                        "Name cannot contain spaces or special characters!",
                                    },
                                  }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      className={classes.textField}
                                      value={value}
                                      placeholder={"Queue Name "}
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : " "}
                                      InputProps={{
                                        disableUnderline: true,
                                        className: error
                                          ? classes.inputError
                                          : "",
                                      }}
                                      required
                                    />
                                  )}
                                />
                              </div>
                              <div>
                                <Controller
                                  name="deviceName"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Device name cannot be empty",
                                    pattern: {
                                      value: /^[a-z0-9]+$/i,
                                      message:
                                        "Name cannot contain spaces or special characters!",
                                    },
                                  }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      className={classes.textField}
                                      placeholder={"Device Name "}
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : " "}
                                      value={value}
                                      autoComplete={false}
                                      InputProps={{
                                        disableUnderline: true,
                                        className: error
                                          ? classes.inputError
                                          : "",
                                      }}
                                      required
                                    />
                                  )}
                                />
                              </div>
                              <div>
                                {alert.message && (
                                  <span className={classes.warning}>
                                    {alert.message}
                                  </span>
                                )}
                              </div>
                              <div className={classes.checkBox}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={withDevice}
                                      onChange={handleCheckChange}
                                      className={classes.checkBox}
                                    />
                                  }
                                  label="I want to connect with QM Device"
                                />
                              </div>
                              {showDeviceError && (
                                <span className={classes.warning}>
                                  {showDeviceError}
                                </span>
                              )}

                              <div>
                                <Button variant="contained" type="submit">
                                  Add Now
                                  {isLoadingSpinner ? <LoadingSpinner /> : null}
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </VisualViewport>
                  ) : (
                    <div style={{ margin: "12px" }}>
                      {!isLoading ? (
                        deviceList && deviceList.length ? (
                          <Grid container spacing={2}>
                            {deviceList.map((list, index) => {
                              const device = list.split("_")[1];
                              const queue =
                                list.split("_")[2] &&
                                list
                                  .split("_")[2]
                                  .substring(
                                    0,
                                    list.split("_")[2].indexOf(".")
                                  );
                              const checkId = list.replace(".json", "");
                              return (
                                <Grid item xs={12} md={6} lg={4} sm={6}>
                                  <ListItem
                                    className={classes.listName}
                                    component="div"
                                    key={index}
                                    onClick={(e) => toggleDrawer(e, checkId)}
                                  >
                                    <ListItemIcon
                                      onClick={(e) => toggleDrawer(e, checkId)}
                                      style={{
                                        borderRight: "1px solid #D8D8D8",
                                      }}
                                    >
                                      <DeviceIcon
                                        color="primary"
                                        style={{
                                          height: "90px",
                                          marginRight: "20px",
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={queue}
                                      onClick={(e) => toggleDrawer(e, checkId)}
                                    ></ListItemText>
                                    {/* <ListItemIcon
                              onClick={() => handleList(device, queue)}
                            >
                              <ChevronRightIcon color="primary" />
                            </ListItemIcon> */}
                                    {/* </ListItemButton> */}
                                  </ListItem>
                                  {/* </div> */}
                                  {!addDevice && (
                                    <Grid
                                      item
                                      xs={1}
                                      className={classes.addBtn}
                                    >
                                      <Fab
                                        color="primary"
                                        aria-label="add"
                                        onClick={handleAddDevice}
                                      >
                                        <AddIcon />                                 
                                      </Fab>
                                    </Grid>
                                  )}
                                </Grid>
                              );
                            })}
                            <Drawer
                              className={classes.drawer}
                              variant={"temporary"}
                              classes={{
                                paper: classes.drawerPaper,
                              }}
                              anchor="right"
                              open={openDrawer}
                              onClose={handleCloseDrawer}
                            >
                              <div className={classes.toolbar} />
                              <Home
                                newId={newId}
                                id={token}
                                currentNumber={currentNumber}
                                setCurrentNumber={setCurrentNumber}
                                totalCount={totalCount}
                                setTotalNumber={setTotalNumber}
                                getUserDetails={getUserDetails}
                                handleCloseDrawer={handleCloseDrawer}
                                setOpenDrawer={setOpenDrawer}
                              />
                            </Drawer>
                          </Grid>
                        ) : (
                          <Box className={classes.noQueue}>
                            <ReactSVG src={queueNotFound} />
                            <p>No Queue Found</p>
                            <Grid
                              item
                              xs={12}
                              style={{ marginTop: 50, marginBottom: 30 }}
                            >
                              <div>
                                <Button
                                  style={{
                                    backgroundColor: "#0386ee",
                                    color: "white",
                                    borderRadius: "7px",
                                  }}
                                  type="submit"
                                  onClick={handleAddDevice}
                                >
                                  Add Queue
                                </Button>
                              </div>
                            </Grid>
                          </Box>
                        )
                      ) : (
                        <CircularProgress
                          className={classes.queueLoader}
                          color="primary"
                        />
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
              <CModal open={open} handleClose={handleClose}>
                <div>
                  <Box>
                    <img src={qrScan} alt="QR code" />
                  </Box>
                  <p>Please check internet connection then reload page</p>
                </div>
              </CModal>
              <Modal
                open={openImgName}
                onClose={handleCloseImgName}
                disableBackdropClick={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  className={
                    classes.modal + " " + profileClasses.changePassword
                  }
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className={profileClasses.modalHeader}
                  >
                    Enter Details
                  </Typography>
                  <img
                    src={image}
                    // alt="prifile_pic"
                    name="file"
                    className="img-thumbnail"
                    height={100}
                    width={100}
                  />
                  <Box>
                    <label htmlFor="icon-button-file">
                      <Input
                        style={{ display: "none" }}
                        className="form-control"
                        name="file"
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        onChange={handleUploadImg}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setCheck(!check)}
                      >
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </Box>
                  <TextField
                    fullWidth
                    className={profileClasses.modalBody}
                    required
                    placeholder="Enter Name here..."
                    value={userNameNew}
                    onChange={(e) => {
                      setUserNameNew(e.target.value);
                    }}
                  />
                  <Box className={profileClasses.modalFooter}>
                    {/* <Button variant="outlined" color="error" size="medium" onClick={handleCloseImgName}>
              cancel
            </Button> */}
                    <Button
                      variant="contained"
                      color="success"
                      size="medium"
                      onClick={saveImageAndUserName}
                      disabled={!userNameNew && check}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </div>
            {/* <Box sx={{ pb: 7 }}>
        <CssBaseline />
        <div className={classes.root}>
          <div className={classes.rootLogo}>
          </div>
          {bottomTabValue === 0 && <Queue />}
          {bottomTabValue === 1 && <Device />}
          {bottomTabValue === 2 && (
            <Profile
              signOut={signOut}
              bottomTabValueHandler={bottomTabValueHandler}
            />
          )}
        </div>
        <Paper className={classes.bottomNavBar} elevation={3}>
          <BottomNavigation
            showLabels
            value={bottomTabValue}
            onChange={(event, newValue) => {
              setBottomTabValue(newValue);
            }}
          >
            <BottomNavigationAction
              icon={<img src={queue} alt="queueTabIcon" />}
              label="Queue"
            />
            <BottomNavigationAction
              icon={<ReactSVG src={device} alt="deviceTabIcon" />}
              label="Device"
            />
            <BottomNavigationAction
              icon={<ReactSVG src={user} alt="profileTabIcon" />}
              label="Profile"
            />
          </BottomNavigation>
        </Paper>
      </Box> */}
          </div>
        </>
      )}
    </>
  ) : authState === "signedIn" && isModalOpen ? (
    <>
      {/* {console.log("isModalOpen", isModalOpen, newValue)} */}
      {/* {console.log("main3")} */}

      <ProfileSetup udpateValue={() => setNewValue(true)} />
    </>
  ) : null;
};

export default MainLayout;
