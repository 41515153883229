import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  getCurrentCount,
  removeMeWaitlistQueue,
  getDelayMessage,
} from "../api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../assests/css/style.css";
import { timeConverter } from "../services/utils";
import moment from "moment";
import { ReactComponent as Clock } from "../assests/images/clock.svg";
import { ReactComponent as CharacterIcon } from "../assests/images/characterIcon.svg";
import { colorStyles } from "../assests/css/color";
import { HeaderMock } from "../component/HeaderMock";
import { Footer } from "../component/Footer";
import AudioPlayer from "react-h5-audio-player";
import { Button } from "@material-ui/core";
import LoadingSpinner from "../component/auth-components/LoadingSpinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

// moment.updateLocale('en', {
//   invalidDate : String
// });
const useStyles = makeStyles((theme) => ({
  header: {
    color: "#fff",
    display: "flex",
    fontSize: "20px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: colorStyles.primaryColor,
    backgroundColor: colorStyles.primaryBackgroundColor,
    width: "100%",
    height: 80,
  },
  logo: {
    backgroundColor: "#0386ee",
    backgroundColor: "#07174B",
    width: "100%",
    height: 80,
    "& img": {
      width: 80,
    },
    "& .goBack": {
      // textAlign: "start",
      position: "absolute",
      left: "2px",
      "& span": {
        color: "#0386ee",
      },
    },
  },
  root: {
    textAlign: "-webkit-center",
    // width: "100%",
    // height: 500,
    // padding: 10,
    fontFamily: "'Manrope', sans-serif",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "space-between",
  },
  main: {
    // boxShadow: "0px 0px 1px #262F56",
    borderRadius: 10,
    "& #qr-gen": {
      display: "none",
    },
    // [theme.breakpoints.down("sm")]: {
    //   width: "90%",
    // },
  },
  heading: {
    backgroundColor: "#0386ee",
    height: "70px",
    borderRadius: "10px",
    display: "flex",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
    width: 360,
    [theme.breakpoints.up("md")]: {
      width: 400,
      padding: "0px 30px",
    },
    "& span": {
      margin: "13px 0px",
      padding: "10px",
      borderRadius: "10px",
      background: "#aaed07",
      fontWeight: 500,
      color: "#fff",
    },
    "& .waitingTimeLoader": {
      display: "flex",
      alignItems: "center",
      "& > span": {
        background: "none",
      },
    },
  },
  innerSec: {
    display: "flex",
    margin: 25,
    height: "40px",
    justifyContent: "center",
    border: "1px solid #0386ee",
    width: "max-content",
    padding: "20px",
    alignItems: "center",
    borderRadius: "10px",
    "& p": {
      margin: "0px 15px",
    },
  },
  innerSecCurrent: {
    display: "flex",
    margin: 25,
    height: "40px",
    background: "#04ee50",
    color: "#fff",
    justifyContent: "center",
    border: "1px solid #0386ee",
    width: "max-content",
    padding: "20px",
    alignItems: "center",
    borderRadius: "10px",
    "& p": {
      margin: "0px 15px",
    },
  },
  waitlistBtn: {
    margin: 20,
    marginTop: 80,
    "& Button": {
      backgroundColor: "#0386ee",
      color: "#fff",
      borderRadius: "10px",
      padding: "0px 30px",
      height: "50px",
    },
    "& Button:hover": {
      color: "#0386ee",
      border: "1px solid #0386ee",
      backgroundColor: "#fff",
    },
  },
  removeWaitlistBtn: {
    margin: 20,
    "& Button": {
      color: "white",
      background: "red",
      border: "1px solid red",
      height: "50px",
      borderRadius: "10px",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#e74d4d",
      border: "1px solid #e74d4d",
      boxShadow: "none",
    },
  },
  audioPlayer: {
    "& .rhap_main-controls-button": {
      color: "white",
      marginTop: "-35px",
      marginLeft: "-208px",
    },
    "& .rhap_time": {
      color: "white",
    },
    "& .rhap_progress-section": {
      display: "flex",
      flex: "3 1 auto",
      alignItems: "center",
      marginLeft: "35px",
      marginTop: "10px",
    },
    "& .rhap_play-pause-button": {
      fontSize: "30px",
      width: "40px",
      height: "40px",
    },
    "& .rhap_progress-bar-show-download": {
      backgroundColor: "hsla(252,13%,46%,1)",
    },
    "& .rhap_progress-filled": {
      backgroundColor: "white",
    },
    "& .rhap_container": {
      border: "1px solid #0386ee",
      backgroundColor: "#060543",
      width: "253px !important",
    },
  },
}));
const customStyle = {
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: "0px",
  position: "absolute",
  width: "100%",
  // backgroundColor: "red",
  // height: 30,
};

const RegistrationSuccess = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const count = location.state && location.state.count;
  const [confirmTimeSlot, setconfirmTimeSlot] = useState(
    location.state && location.state.confirmTimeSlot
  );
  // const confirmTimeSlot = location.state && location.state.confirmTimeSlot;
  const queueName = location.state && location.state.queueName;

  let { id } = useParams();
  let urlId = useParams();

  id = atob(id);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userRegistrationDateTime, setUserRegistrationDateTime] = useState(
    location.state && location.state.confirmTimeSlot
  );
  const [userCount, setUserCount] = useState(count);
  const [estimatedWaitTime, setEstimatedWaitTime] = useState(0);
  const [waitDuration, setWaitDuration] = useState(0);
  const [queueDuration, setQueueDuration] = useState(5);
  const [queueOpeningTime, setQueueOpeningTime] = useState(null);
  const [userMessage, setUserMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [isReCheck, setIsCheck] = useState(true);
  const [deviceName, setDeviceName] = useState("");
  const [audioMessage, setAudioMessage] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [hasAudio, setHasAudio] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [isSlotSelectForTomorrow, setisSlotSelectForTomorrow] = useState(false);
  const [secondaryIndex, setSecondaryIndex] = useState(
    localStorage.getItem(`${id}_userTimeSlotSecondaryKey`)
  );
  let ws;
  const notify = () => {
    toast.info(audioMessage, { autoClose: true });
  };
  let connected = false;
  useEffect(() => {
    if (audioMessage) {
      notify();
    }
  }, [audioMessage]);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(id));
    if (!userDetails) {
      history.push({
        pathname: `/tr/${urlId.id}`,
      });
    }
    if (userDetails && !userDetails.date) {
      history.push({
        pathname: `/tr/${urlId.id}`,
      });
    }
    if (userDetails && userDetails.date) {
      let date = userDetails.date;
      let dateLast = date.split("/")[0];
      let slotBookTime = moment(dateLast, "YYYY-MM-DD");
      let slotBookTimeFormat = slotBookTime.format("YYYY-MM-DD");
      const todayDate = moment().format("YYYY-MM-DD");
      if (slotBookTimeFormat != todayDate) {
        history.push({
          pathname: `/tr/${urlId.id}`,
        });
        console.log("dateTimeSuccess", "yes");
      }
    }
  }, []);
  useEffect(() => {
    // setIsLoading(true);
    fetchCurrentCount();
  }, []);

  // useEffect(() => {
  //   if (isReCheck) {
  //     const interval = setInterval(() => {
  //       fetchCurrentCount();
  //       calculateWaitDuration();
  //       getUserMessage();
  //     }, 1000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // });
  // useEffect(() => {
  // console.log(
  //   "location.state && location.state.count",
  //   location.state && location.state.confirmTimeSlot
  // );
  // }, []);

  const fetchCurrentCount = () => {
    setIsCheck(false);
    getCurrentCount(id, userRegistrationDateTime)
      .then((res) => {
        setIsCheck(true);
        setCurrentNumber(res.currentNum);
        setEstimatedWaitTime(res.appointmentTime);
        setQueueDuration(res.duration);
        setQueueOpeningTime(res.openingTime);
        calculateWaitDuration();
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  const calculateWaitDuration = () => {
    if (userCount === currentNumber) {
      setWaitDuration(0);
    } else {
      let waitingDate = moment(estimatedWaitTime, "hh:mm a");
      let waitMinutes = waitingDate.diff(moment(), "minutes");
      let minimumWaitTime = (userCount - (currentNumber + 1)) * queueDuration;
      setWaitDuration(
        waitMinutes < minimumWaitTime ? minimumWaitTime : waitMinutes
      );
    }
  };

  // const getUserMessage = () => {
  //   const defaultMessage = `You are enrolled in line at ${
  //     id && id.split("_")[2]
  //   }.`;
  //   const queueStartMessage = `You are at position: ${userCount}. ${
  //     queueOpeningTime ? `Queue will start at ${queueOpeningTime}` : ""
  //   }`;
  //   const waitingTimeMessage = `Your estimated waiting time is ${
  //     isNaN(waitDuration) || waitDuration <= 0 ? 0 : timeConverter(waitDuration)
  //   }.`;

  //   switch (true) {
  //     case currentNumber === 0:
  //       setUserMessage(defaultMessage + " " + queueStartMessage);
  //       setButtonText("Wait for turn");
  //       break;

  //     case currentNumber === userCount - 1:
  //       setUserMessage("You are next in line. Please be ready.");
  //       setButtonText("Wait for turn");
  //       break;

  //     case currentNumber === userCount:
  //       setUserMessage("Please reach to the start of the queue.");
  //       setButtonText("Its your turn now");
  //       break;

  //     case waitDuration > 0:
  //       setUserMessage(defaultMessage + " " + waitingTimeMessage);
  //       setButtonText("Wait for turn");
  //       break;

  //     case currentNumber > userCount:
  //       setUserMessage("Your turn is completed");
  //       setButtonText("Turn is over");
  //       break;

  //     default:
  //       if (waitDuration === 0 && currentNumber === userCount) {
  //         setUserMessage(defaultMessage);
  //         setButtonText("It's your turn");
  //       } else {
  //         setUserMessage(defaultMessage + " " + waitingTimeMessage);
  //         setButtonText("Wait for turn");
  //       }
  //   }

  //   if (userMessage) setIsLoading(false);
  // };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(id));
    setUserCount(userDetails && userDetails.count);
    console.log("settingUserRegisterTime", userDetails && userDetails.date);
    setUserRegistrationDateTime(userDetails && userDetails.date);
    if (
      moment(userDetails && userDetails.date, "YYYY-MM-DD/HH:mm:ss").format(
        "DD/MM/YYYY"
      ) != moment(new Date()).format("DD/MM/YYYY")
    ) {
      console.log("slotTomorrow");
      setisSlotSelectForTomorrow(true);
    }
  }, []);

  const userData =
    typeof window !== "undefined" ? JSON.parse(localStorage.getItem(id)) : null;

    const handleRemoveQueue = () => {
      setIsLoading(true);
      setIsLoadingSpinner(true);
      setAudioMessage("");
      const data = {
        key: localStorage.getItem(`${id}_userTimeSlotSecondaryKey`),
      };
      removeMeWaitlistQueue(data)
        .then((res) => {
          if (res.status === 200) {
            typeof window !== "undefined" && localStorage.removeItem(id);
            localStorage.removeItem("isSlotSelectedForTomorrow");
            localStorage.removeItem(`${id}_audio`);
            localStorage.removeItem(`${id}_message`);
            localStorage.removeItem(`${id}_audioset`);
            localStorage.removeItem(`${id}_delayTime`);
            localStorage.clear();
            setIsLoading(false);
            const hashId = btoa(id);
            console.log("fromHere");
  
            history.push(`/tr/${hashId}`);
            setIsLoadingSpinner(false);
          }
        })
        .catch((err) => console.log(err));
      // let removeuserRegistrationDateTime = userRegistrationDateTime;
      // if (localStorage.getItem(`${id}_delayTime`)) {
      //   // const delayTimeSecond = localStorage.getItem(`${id}_delayTime`);
      //   // removeuserRegistrationDateTime = moment(removeuserRegistrationDateTime)
      //   //   .add(delayTimeSecond, "seconds")
      //   //   .format("YYYY-MM-DD/HH:mm:ss");
      //   // console.log(
      //   //   "removeuserRegistrationDateTime",
      //   //   moment(removeuserRegistrationDateTime)
      //   //     .subtract(delayTimeSecond, "seconds")
      //   //     .format("YYYY-MM-DD/HH:mm:ss")
      //   // );
      //   // console.log(
      //   //   "removeuserdataRegistrationDateTime",
      //   //   userRegistrationDateTime
      //   // );
      //   // const data = {
      //   //   Device_Name: `${id}_App`,
      //   //   Date_Time: moment(userRegistrationDateTime)
      //   //     .add(localStorage.getItem(`${id}_delayTime`), "seconds")
      //   //     .format("YYYY-MM-DD/HH:mm:ss"),
      //   // };
      //   const data = {
      //     Device_Name: `${id}_App`,
      //     Date_Time: userRegistrationDateTime,
      //   };
      //   // console.log("removeuserRegistrationDateTimeIf", data);
      //   removeMeWaitlistQueue(data)
      //     .then((res) => {
      //       if (res.status === 200) {
      //         typeof window !== "undefined" && localStorage.removeItem(id);
      //         localStorage.removeItem("isSlotSelectedForTomorrow");
      //         localStorage.removeItem(`${id}_audio`);
      //         localStorage.removeItem(`${id}_message`);
      //         localStorage.removeItem(`${id}_audioset`);
      //         localStorage.removeItem(`${id}_delayTime`);
      //         localStorage.clear();
      //         setIsLoading(false);
      //         const hashId = btoa(id);
      //         console.log("fromHere");
  
      //         history.push(`/tr/${hashId}`);
      //         setIsLoadingSpinner(false);
      //       }
      //     })
      //     .catch((err) => console.log(err));
      // } else {
      //   // console.log(
      //   //   "removeuserRegistrationDateTimeelse",
      //   //   userRegistrationDateTime
      //   // );
  
      //   const data = {
      //     Device_Name: `${id}_App`,
      //     Date_Time: userRegistrationDateTime,
      //   };
      //   // console.log("removeuserRegistrationDateTime", data);
      //   removeMeWaitlistQueue(data)
      //     .then((res) => {
      //       if (res.status === 200) {
      //         typeof window !== "undefined" && localStorage.removeItem(id);
      //         localStorage.removeItem("isSlotSelectedForTomorrow");
      //         localStorage.removeItem(`${id}_audio`);
      //         localStorage.removeItem(`${id}_message`);
      //         localStorage.removeItem(`${id}_audioset`);
  
      //         setIsLoading(false);
      //         const hashId = btoa(id);
      //         console.log("fromHere");
  
      //         history.push(`/tr/${hashId}`);
      //         setIsLoadingSpinner(false);
      //       }
      //     })
      //     .catch((err) => console.log(err));
      // }
    };
  // console.log("confirmTimeSlotconfirmTimeSlot", confirmTimeSlot);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(id));
    // setUserCount(userDetails && userDetails.count);
    console.log("responsedataHistory", userDetails);
    console.log("settingUserRegisterTime", userDetails && userDetails.date);

    setUserRegistrationDateTime(userDetails && userDetails.date);
    // console.log("deviceNameForWebSocket", userDetails.deviceName);

    setDeviceName(userDetails && userDetails.deviceName);
    // let deviceNameForWebSocket = `${deviceName}_App`
    // initWebSocket(deviceNameForWebSocket)
  }, []);
  useEffect(() => {
    // setDeviceName(userDetails && userDetails.deviceName);
    let deviceNameForWebSocket = `${deviceName}_App`;
    console.log("deviceNameForWebSocket", deviceNameForWebSocket);
    if (deviceName != "") {
      initWebSocket(deviceNameForWebSocket);
    }
  }, [deviceName]);
  const convertToSeconds = (b) => {
    console.log("convertToSeconds", b);
    var a = b.split(":");
    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    return seconds;
  };
  const getCurrentCountFromApi = () => {
    console.log("got called");
    getDelayMessage(id, secondaryIndex)
      .then((res) => {
        console.log("SecondaryINdex", res);

        if (res.status === 200) {
          let tempData = res.data;
          let dateTime = res.data.userTimeSlot;
          // dateTime = new Date(dateTime);
          // dateTime = moment(dateTime).format("YYYY-MM-DD/HH:mm:sss");
          console.log("confirmTimeSlotState", confirmTimeSlot);
          console.log("confirmTimeSlotState", dateTime);
          console.log("settingTime");
          setconfirmTimeSlot(dateTime);
          console.log("settingUserRegisterTime", dateTime);

          setUserRegistrationDateTime(dateTime);
          const userDetails = JSON.parse(localStorage.getItem(id));
          userDetails.date = dateTime;
          console.log("localsettingTime");

          localStorage.setItem(id, JSON.stringify(userDetails));
          console.log("SecondaryINdex", res.data.setTime);
          console.log(
            "SecondaryINdex",
            moment(res.data.userRegistrationTime).format("HH:mm:ss")
          );
          let userTimeSlot = moment(res.data.userTimeSlot).format("HH:mm:ss");
          let closingTime = moment(res.data.closingTime).format("HH:mm:ss");
          let audioSetTime = res.data.setTime;
          console.log("SecondaryINdex", audioSetTime);
          console.log("SecondaryINdex", userRegistrationTime);
          let userRegistrationTime = moment(
            res.data.userRegistrationTime
          ).format("HH:mm:ss");

          if (userTimeSlot > closingTime) {
            // console.log("SecondaryINdex1");
            console.log("slotTomorrow");

            setisSlotSelectForTomorrow(true);
          } else if (userTimeSlot < closingTime) {
            // console.log("SecondaryINdex2");
          }
          if (audioSetTime > userRegistrationTime) {
            console.log("SecondaryINdex1");
            setAudioUrl(res.data.link);
            setAudioMessage(res.data.message);
            setHasAudio(true);
          } else if (audioSetTime < userRegistrationTime) {
            console.log("SecondaryINdex2");
            setAudioMessage("");
            setAudioUrl("");
          }
        } else if (res.status === 201) {
          let tempData = res.data;
          let dateTime = res.data.userTimeSlot;
          // dateTime = new Date(dateTime);
          // dateTime = moment(dateTime).format("YYYY-MM-DD/HH:mm:sss");
          console.log("settingUserRegisterTime", dateTime);
          setUserRegistrationDateTime(dateTime);
          const userDetails = JSON.parse(localStorage.getItem(id));
          userDetails.date = dateTime;
          console.log("localsettingTime");

          localStorage.setItem(id, JSON.stringify(userDetails));
          console.log(
            "SecondaryINdex",
            moment(res.data.userTimeSlot).format("HH:mm:ss")
          );
          console.log(
            "SecondaryINdex",
            moment(res.data.closingTime).format("HH:mm:ss")
          );
        }
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };
  function initWebSocket(deviceNameForWebSocket) {
    let wsUrl = "wss://mp63syzhk9.execute-api.eu-central-1.amazonaws.com/test";
    // if (wsUrl) {
    ws = new WebSocket(wsUrl);
    initWebSocketsEvents(deviceNameForWebSocket);
    // console.log("inside init");
    // console.log("id id id id id id id id id ", deviceNameForWebSocket);
    // }
  }
  function initWebSocketsEvents(deviceNameForWebSocket) {
    // for delaytime add from settime in local
    // let slotBookTime = localStorage.getItem(`${id}_dateTime`);
    // console.log("dateTimeSuccess",localStorage.getItem(`${id}_dateTime`));

    // let dateLa  st = slotBookTime;
    // // let dateTimeLocal = new Date(slotBookTime);
    // console.log("dateTimeSuccess",typeof dateLast);

    // let dateTime=moment(slotBookTime, 'HH:mm:ss');
    // console.log("dateTimeSuccess",typeof dateTime);

    // // dateTime = moment(dateTime);
    // dateTime = dateTime.format("HH:mm:ss");
    // console.log("dateTimeSuccess", dateTime);
    // console.log("dateTimeSuccess", delayTime);
    // // dateTime = moment(dateTime)
    // //   .add(delayTime, "seconds")
    // //   .format("HH:mm:ss");
    //   dateTime = moment(dateTime, 'HH:mm:ss').add(delayTime, 'seconds');
    // // dateTime = dateTime + dateLast;
    // console.log("dateTimeSuccess", dateTime.format('HH:mm:ss'));
    ws.onopen = function (k) {
      // ws.send(JSON.stringify({action: 'default', k : k}));
      connected = true;
      sendFirst(deviceNameForWebSocket);
    };

    ws.onmessage = function (evt) {
      let message = evt.data;
      let parsedMessage = JSON.parse(message);
      console.log("getDelayMessage", parsedMessage);
      let audio = parsedMessage.audio;
      if (parsedMessage.Count) {
        let updatedCount = parsedMessage.Count;
        setCurrentNumber(updatedCount);
      }
      getCurrentCountFromApi();
      // else if (parsedMessage.audio) {
      //   if (audio && audio !== "no audio") {
      //     let updatedAudio = audio && audio.split("_");
      //     updatedAudio = updatedAudio[updatedAudio.length - 1];
      //     updatedAudio = updatedAudio.split(".")[0];
      //     updatedAudio = updatedAudio.match(
      //       /(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g
      //     )[1];
      //     updatedAudio = updatedAudio.replace("-", ":");
      //     updatedAudio = updatedAudio.replace("-", ":");
      //     let firstTime = localStorage.getItem(`${id}_dateTime`);
      //     if (firstTime > updatedAudio) {
      //       setAudioMessage("");
      //       setAudioUrl("");
      //     }
      //   }
      //   let message = parsedMessage.message;
      //   let closingTime = parsedMessage.closingTime;

      //   setAudioMessage(message);
      //   setHasAudio(true);
      //   localStorage.setItem(`${id}_audio`, audio);
      //   localStorage.setItem(`${id}_message`, message);
      //   setAudioUrl(audio);
      //   console.log("test", parsedMessage.delayTime);
      //   let delayTime = convertToSeconds(parsedMessage.delayTime);
      //   const userDetails = JSON.parse(localStorage.getItem(id));
      //   if (userDetails.date) {
      //     let date = userDetails.date;
      //     console.log("dateTimeSuccess", date);
      //     let dateTime = date;
      //     let dateLast = date.substring(19);
      //     dateTime = dateTime.substring(0, 19);
      //     dateTime = new Date(dateTime);

      //     dateTime = moment(dateTime)
      //       .add(delayTime, "seconds")
      //       .format("YYYY-MM-DD/HH:mm:ss");
      //     dateTime = dateTime + dateLast;
      //     // let dateLast = date;
      //     // let slotBookTime = date;
      //     // let dateTime = dateLast.split("/")[1];
      //     // dateTime = moment(dateTime, "HH:mm:ss");
      //     // dateTime = dateTime.format("HH:mm:ss");
      //     // dateTime = moment(dateTime, "HH:mm:ss").add(delayTime, "seconds");
      //     // console.log("dateTimeSuccess", dateTime.format("HH:mm:ss"));

      //     setconfirmTimeSlot(dateTime);
      //     console.log("settingUserRegisterTime", dateTime);

      //     setUserRegistrationDateTime(dateTime);
      //     // localStorage.setItem(`${id}_audioset`, 1);
      //     if (dateTime < closingTime) {
      //       if (localStorage.getItem(`${id}_audioset`) == "1") {
      //         console.log("insideDAtaTIme1");
      //       } else {
      //         console.log("insideDAtaTIme2");
      //         localStorage.setItem(`${id}_audioset`, 1);
      //         console.log("settingUserRegisterTime", dateTime);

      //         setUserRegistrationDateTime(dateTime);
      //         const userDetails = JSON.parse(localStorage.getItem(id));
      //         userDetails.date = dateTime;
      //         localStorage.setItem(id, JSON.stringify(userDetails));
      //         console.log("after after ", userRegistrationDateTime);
      //       }
      //       console.log("insideDAtaTIme3");
      //     } else {
      //       setShowModal(true);
      //     }
      //   } else {
      //     history.push({
      //       pathname: `/tr/${urlId.id}`,
      //     });
      //   }
      // }
    };

    ws.onclose = function (k) {
      initWebSocket(deviceNameForWebSocket);
      connected = false;
    };

    ws.onerror = function (error) {
      console.error(error);
    };
  }
  function sendFirst(deviceNameForWebSocket) {
    // if (connected) {
    let message = deviceNameForWebSocket;
    // if (message) {
    ws.send(JSON.stringify({ action: "default", message }));
  }
  // console.log("getDelayMesssage",`${id}_dateTime`);
  useEffect(() => {
    getDelayMessage(id, secondaryIndex)
      .then((res) => {
        console.log("SecondaryINdex", res);

        if (res.status === 200) {
          let dateTime = res.data.userTimeSlot;
          // dateTime = new Date(dateTime);
          // dateTime = moment(dateTime).format("YYYY-MM-DD/HH:mm:sss");
          console.log("confirmTimeSlotState", confirmTimeSlot);
          console.log("confirmTimeSlotState", dateTime);
          console.log("settingTime", dateTime);
          setconfirmTimeSlot(dateTime);
          setUserRegistrationDateTime(dateTime);
          const userDetails = JSON.parse(localStorage.getItem(id));
          userDetails.date = dateTime;
          console.log("localsettingTime");

          localStorage.setItem(id, JSON.stringify(userDetails));
          let tempData = res.data;
          console.log("SecondaryINdex", res.data.setTime);
          console.log(
            "SecondaryINdex",
            moment(res.data.userRegistrationTime).format("HH:mm:ss")
          );
          let userTimeSlot = moment(res.data.userTimeSlot).format("HH:mm:ss");
          let closingTime = moment(res.data.closingTime).format("HH:mm:ss");
          let audioSetTime = res.data.setTime;
          let userRegistrationTime = moment(
            res.data.userRegistrationTime
          ).format("HH:mm:ss");
          console.log("SecondaryINdex", audioSetTime);
          console.log("SecondaryINdex", userRegistrationTime);
          if (userTimeSlot > closingTime) {
            // console.log("SecondaryINdex1");
            console.log("slotTomorrow");

            setisSlotSelectForTomorrow(true);
          } else if (userTimeSlot < closingTime) {
            // console.log("SecondaryINdex2");
          }
          if (audioSetTime > userRegistrationTime) {
            console.log("SecondaryINdex1");
            setAudioUrl(res.data.link);
            setAudioMessage(res.data.message);
            setHasAudio(true);
          } else if (audioSetTime < userRegistrationTime) {
            console.log("SecondaryINdex2");
            setAudioMessage("");
            setAudioUrl("");
          }
        } else if (res.status === 201) {
          let tempData = res.data;
          let dateTime = res.data.userTimeSlot;
          // dateTime = new Date(dateTime);
          // dateTime = moment(dateTime).format("YYYY-MM-DD/HH:mm:sss");
          console.log("settingTime");
          setUserRegistrationDateTime(dateTime);
          const userDetails = JSON.parse(localStorage.getItem(id));
          userDetails.date = dateTime;
          console.log("localsettingTime");

          localStorage.setItem(id, JSON.stringify(userDetails));
          console.log(
            "SecondaryINdex",
            moment(res.data.userTimeSlot).format("HH:mm:ss")
          );
          console.log(
            "SecondaryINdex",
            moment(res.data.closingTime).format("HH:mm:ss")
          );
        }
      })
      .catch((err) => {
        // console.log(err.message);
      });
  }, []);
  useEffect(() => () => localStorage.clear(), []);

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.clear();
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  useEffect(() => {
    // setIsLoading(true);
    let delayAudio = localStorage.getItem(`${id}_audio`);
    let delayMessage = localStorage.getItem(`${id}_message`);
    console.log("delaySet", typeof delayAudio);
    if (delayAudio === "undefined" || delayAudio == null) {
      setAudioUrl("");
      setAudioMessage("");
      setHasAudio(false);
    } else if (delayAudio !== null || delayAudio !== undefined) {
      console.log("delaySet", delayAudio);
      setAudioUrl(delayAudio);
      setAudioMessage(delayMessage);
      setHasAudio(true);
    }
    // if (time === undefined) {
    //   if (localStorage.getItem(id)) {
    //     const _user = localStorage.getItem(id);
    //     if (_user) {
    //       const user = JSON.parse(_user);
    //       const assignDate = user.date;
    //       time = assignDate;
    //       fetchCurrentCount();
    //     }
    //   } else {
    //     const hashId = btoa(id);
    //     history.push(`/tr/${hashId}`);
    //   }
    // }
    fetchCurrentCount();
  }, []);
  const changeUrl = () => {
    // handleRemoveQueue();
    typeof window !== "undefined" && localStorage.removeItem(id);
    // console.log("changeURl");
    localStorage.removeItem("isSlotSelectedForTomorrow");
    localStorage.removeItem(`${id}_audio`);
    localStorage.removeItem(`${id}_message`);
    setIsLoading(false);
    const hashId = btoa(id);
    history.push(`/tr/${hashId}`);
    setIsLoadingSpinner(false);
  };
  const NextDatSlotSelection = () => (
    <div>
      <div style={{ marginTop: "230px" }}>
        <div style={{ fontWeight: "bold" }}>
          Your slot has been cancelled.
          <div>
            <div>you can book slot for tommorrow.</div>
            <Button
              variant="contained"
              onClick={changeUrl}
              style={{
                color: "white",
                backgroundColor: "midnightblue",
                marginTop: "10px",
              }}
            >
              Register
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
  React.useEffect(() => {
    const isSlotSelectedForTomorrowSelected = localStorage.getItem(
      "isSlotSelectedForTomorrow"
    );
    if (isSlotSelectedForTomorrowSelected) {
      console.log("slotTomorrow");

      setisSlotSelectForTomorrow(false);
    }
    // localStorage.setItem("isSlotSelectedForTomorrow", true);
  }, []);
  {
    console.log("isSlotSelectForTomorrow", isSlotSelectForTomorrow);
  }
  {
    console.log("isSlotSelectForTomorrow", showModal);
  }
  return (
    <>
      <div className={classes.root} style={{}}>
        {/* <div className={classes.logo}>
        <div className="goBack">
          <IconButton
            component="span"
            onClick={() => {
              history.goBack();
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
      </div> */}
        <HeaderMock />
        <div style={{ flex: 1 }}>
          {isSlotSelectForTomorrow || showModal ? (
            <NextDatSlotSelection />
          ) : (
            <>
              <table style={{ marginTop: "30px" }}>
                <tr className="tableData1">
                  <td textAlign="left">Queue Name :</td>
                  <td style={{ fontWeight: "bold" }}>{queueName}</td>
                </tr>
                <tr className="tableData2">
                  <td textAlign="left">Appointment : </td>

                  <td style={{ fontWeight: "bold" }}>
                    {moment(
                      userRegistrationDateTime,
                      "YYYY-MM-DD/HH:mm:ss"
                    ).format("hh:mm A")}
                    ,{" "}
                    {moment(
                      userRegistrationDateTime,
                      "YYYY-MM-DD/HH:mm:ss"
                    ).format("DD MMM")}
                  </td>
                </tr>
              </table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  <Clock />
                </div>
                <CharacterIcon />
              </div>
              {audioUrl !== "no audio" ? (
                <div className={classes.audioPlayer}>
                  <AudioPlayer
                    // style={{ width: "300px" }}
                    style={
                      hasAudio
                        ? { borderRadius: "1rem", width: "300px" }
                        : { display: "none" }
                    }
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                    // layout="horizontal"
                    src={audioUrl}
                    onPlay={(e) => console.log("onPlay")}
                    // showSkipControls={true}
                    showJumpControls={false}
                    customAdditionalControls={[]}
                    customVolumeControls={[]}
                    preload="metadata"
                    timeFormat="mm:ss"
                    // header={`Now playing: ${musicTracks[trackIndex].name}`}
                    // footer="All music from: www.bensound.com"
                    // onClickPrevious={handleClickPrevious}
                    // onClickNext={handleClickNext}
                    // onEnded={handleClickNext}
                  />
                </div>
              ) : null}

              <div className={classes.removeWaitlistBtn}>
                <Button
                  variant="contained"
                  onClick={handleRemoveQueue}
                  disabled={isLoading}
                >
                  Remove me from the waitlist
                  {isLoadingSpinner ? (
                    <LoadingSpinner style={{ marginLeft: "45px" }} />
                  ) : null}
                </Button>
              </div>
            </>
          )}
        </div>
        {/* <span>{confirmTimeSlot} </span> */}

        {/* <div className={classes.main}>
        <div className={classes.heading}>
          {!isLoading ? (
            <p>{userMessage}</p>
          ) : (
            <p className="waitingTimeLoader">
              Calculating estimated waiting time <CircularProgress />{" "}
            </p>
          )}
        </div>
        <div
          className={
            currentNumber === userCount
              ? classes.innerSecCurrent
              : classes.innerSec
          }
        >
          <span>{buttonText ? buttonText : "Wait for turn"}</span>
        </div>
        <div style={{ marginTop: "80px" }}>
          <div className={classes.innerSec}>
            <span>Name :</span>
            <p>{userData && userData.name}</p>
          </div>
          <div className={classes.innerSec}>
            <span>Your Number :</span>
            <p>{userCount}</p>
          </div>
          <div className={classes.innerSec}>
            <span>Current No :</span>
            <p style={{ display: "flex" }}>
              {!(currentNumber == null) ? (
                currentNumber
              ) : (
                <CircularProgress size="20px" />
              )}
            </p>
          </div>
          <div className={classes.removeWaitlistBtn}>
            <Button
              variant="contained"
              onClick={handleRemoveQueue}
              disabled={isLoading}
            >
              Remove me from the waitlist
            </Button>
          </div>
        </div>
      </div> */}
        <Footer style={customStyle} />
      </div>
    </>
  );
};

export default RegistrationSuccess;
