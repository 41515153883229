import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Fab,
  CircularProgress,
  Divider,
  Box,
  Drawer,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import {
  deleteQueue,
  getCurrentCount,
  getTotalCount,
  senWebNotification,
  getDeviceAndQueueList,
} from "../api";
import { getUserInfo } from "../services/getUserInfo";
import { useLocation, useParams, useHistory, Redirect } from "react-router-dom";
import MQTTConnection from "../services/MqttConnection";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RemoveIcon from "@mui/icons-material/Remove";
import { ConfirmationAlert } from "../component";
import moment from "moment";
import Typography from "@mui/material/Typography";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { constants } from "../constants/constant";
import MqttDeleteConnection from "../services/MqttDeleteConnection";
import SettingsIcon from "@material-ui/icons/Settings";
const useStyles = makeStyles((theme) => ({
  drawer: {
    "& .MuiDrawer-paper": {
      width: "30%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
  drawerPaper: {
    width: 200,
  },
  logo: {
    "& img": {
      width: 80,
    },
  },
  root: {
    textAlign: "-webkit-center",
  },
  main: {
    boxShadow: "none",
    borderRadius: 10,
    width: "100%",
    // padding: 25,
    fontFamily: "'Manrope', sans-serif",
    "& #qr-gen": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiCircularProgress-colorPrimary": {
      position: "absolute",
      top: "50%",
      color: "#0486ee",
    },
  },
  noDisplay: {
    width: "15%",
    transform: "rotate(135deg)",
    fontSize: "50px",
    border: "2px solid  white",
    [theme.breakpoints.only("xs")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.only("md")]: {
      width: "20%",
    },
  },
  NumberDisplay: {
    backgroundColor: "#0386ee",
    border: "1px solid  #0386ee",
    width: "30%",
    borderRadius: "20px",
    paddingTop: "3%",
    paddingBottom: "3%",
  },
  currentNumberDisplay: {
    fontSize: "30px",
    marginRight: "30%",
    fontWeight: "700",
    color: "white",
  },
  totalNumberDisplay: {
    fontSize: "30px",
    marginLeft: "30%",
    color: "white",
  },
  currentNumberDisplayBottom: {
    border: "0.5px solid black",
    paddingLeft: "30px",
    paddingRight: "30px",
    fontWeight: "bolder",
    fontSize: "45px",
    margin: "0px",
  },
  heading: {
    backgroundColor: "#060543",
    height: "91px",
    /* align-self: center; */
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#fff",
    fontSize: 20,
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      // marginRight: 20,
      "& span": {
        fontSize: 30,
        color: "#fff",
        position: "absolute",
        left: "2px",
      },
    },
  },
  counts: {
    marginTop: "16px",
    fontSize: "18px",
  },
  innerSec: {
    display: "flex",
    margin: "30px 25px",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      margin: "0px 15px",
      color: "#0386ee",
      fontSize: "30px  ",
    },
  },
  waitlistBtn: {
    margin: "20px 0px",
    "& Button": {
      backgroundColor: "#060543",
      color: "#fff",
      height: "45px",
      borderRadius: 8,
      width: "200px",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#0386ee",
      border: "1px solid #0386ee",
      boxShadow: "none",
    },
  },
  waitlistBtnQR: {
    margin: "15px 0px",
    "& Button": {
      backgroundColor: "#fff",
      color: "black",
      border: "1px solid #0386ee",
      height: "45px",
      width: "200px",
      borderRadius: 8,
    },
  },
  removeWaitlistBtn: {
    margin: 20,
  },
  backArrow: {
    display: "inline",
  },
  currentCountChange: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    // gap: "40px",
    "& .MuiGrid-root": {
      "& .MuiFab-primary": {
        backgroundColor: "#060543",
      },
      "& .MuiFab-label": {
        "& svg": {
          fill: "white",
        },
      },
    },
  },
  deletelistBtn: {
    // margin: "40px 0px",
    marginTop: "28px",
    marginBottom: "25px",
    "& Button": {
      // backgroundColor: "#e74d4d",
      // border: "1.5px solid red",
      boxShadow: "none",
      color: "red",
      height: "45px",
      borderRadius: 8,
      width: "200px",
      background: "transparent",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#e74d4d",
      border: "1px solid #e74d4d",
      boxShadow: "none",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
  },
  copyButton: {
    width: "5%",
    marginLeft: "20px",
    marginTop: "10px",
  },
  qrcode: {
    width: "50%",
    marginTop: "20px",
    [theme.breakpoints.only("xs")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "30%",
    },
  },
}));

const HomePage = (props) => {
  // console.log("HomePageProps", props.id);
  const classes = useStyles();
  const location = useLocation();
  const count = location.state && location.state.count;
  // let { id } = useParams();
  // const newId = atob(id);
  // const deviceHash = btoa(newId);
  const {
    newId,
    id,
    currentNumber,
    totalCount,
    setCurrentNumber,
    setTotalNumber,
    // setOpenDrawer,
    handleCloseHomeDrawer,
    getUserDetails,
  } = props;
  console.log("newId", newId);
  // console.log("newId",id)
  // const [currentNumber, setCurrentNumber] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  // const [totalCount, setTotalNumber] = useState({ Count: 0 });
  const [mqttClient, setMqttClient] = useState();
  const [isUserLoggedIn, setisUserLoggedIn] = useState(false);
  const [queueQrCode, setQueueQrCode] = useState("");
  const [copied, setCopied] = useState(false);
  const [openDrawerHome, setOpenDrawerHome] = React.useState(false);
  const handleCloseDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawerHome(false);
  };
  useEffect(() => {
    setCopied(false);
  }, []);
  const qrValue = `${constants.DOMAIN}/tr/${id}`;
  const history = useHistory();

  useEffect(() => {
    downloadQRCode();
    if (count) {
      setCurrentNumber(Number(count));
    }
  }, []);
  // useEffect(() => {
  //   console.log(
  //     "amplify-authenticator-authState::",
  //     localStorage.getItem("amplify-authenticator-authState")
  //   );
  //   {
  //     localStorage.getItem("amplify-authenticator-authState") == "signedIn"
  //       ? setisUserLoggedIn(true)
  //       : setisUserLoggedIn(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   getTotalCount(newId)
  //     .then((res) => {
  //       setTotalNumber(res);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // }, []);

  // useEffect(() => {
  //   getUserDetails();
  // }, []);

  const CheckActiveQueue = async () => {
    const details = await getUserInfo();
    const userName = details.email && details.email.split("@")[0];
    fetchDeviceAndQueueList(userName);
  };

  const fetchDeviceAndQueueList = (user) => {
    if (openConfirmation) {
      setisLoading(true);
      setOpenConfirmation(false);
    }
    getDeviceAndQueueList(user)
      .then((res) => {
        if (res.join("").includes(newId)) {
          if (!openConfirmation) setisLoading(false);
        } else {
          history.push("/");
        }
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QR-code.jpg`;
    setQueueQrCode(pngUrl);
    document.body.appendChild(downloadLink);
    // downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const downloadQRCodeAndDownload = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QR-code.jpg`;
    setQueueQrCode(pngUrl);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDeleteQueue = () => {
    if (!openConfirmation) return setOpenConfirmation(true);
    setisLoading(true);
    CheckActiveQueue();
    deleteQueue(newId)
      .then((res) => {
        if (res.status === 200) {
          getUserDetails();
          setisLoading(false);
          handleCloseHomeDrawer();
          // setOpenDrawer(false);
        } else setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        // console.log(err);
      });
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleCountChange = (change) => {
    const data = {
      deviceName: newId,
    };
    if (change === "increase") {
      const number = Number(currentNumber) + 1;
      setCurrentNumber(number);
      data.count = number;
    }
    if (change === "decrease") {
      const number =
        Number(currentNumber) > 0
          ? Number(currentNumber) - 1
          : Number(currentNumber);
      setCurrentNumber(number);
      data.count = number;
    }

    const newIdnewId = {
      Device_Name: `${newId}_Device`,
      Date_Time: moment(new Date()).format("YYYY-MM-DD/HH:mm:ss:SSSS"),
      Count: data.count.toString(),
    };
    mqttClient.publish(`${newId}_Device`, JSON.stringify(newIdnewId));
    // senWebNotification(newIdnewId).then(res => {
    //   console.log('web notification send');
    // }).catch(err => console.log(err))
  };

  function isIncognito(callback) {
    var fs = window.RequestFileSystem || window.webkitRequestFileSystem;

    if (!fs) {
      callback(false);
    } else {
      fs(
        window.TEMPORARY,
        100,
        callback.bind(undefined, false),
        callback.bind(undefined, true)
      );
    }
  }

  isIncognito(function (itIs) {
    if (itIs) {
      // console.log("You are in incognito mode");
    } else {
      // console.log("You are NOT in incognito mode");
    }
  });

  function copyToClipboard() {
    navigator.clipboard.writeText(`${constants.DOMAIN}/tr/${id}`);
    setCopied(true);
  }
  const removeUserHandler = () => {
    // console.log("heyMqttDeleteConnectionSUCCES___");
    // getAnalyticListHandlerWIthoutReload();
    getCurrentCount(newId)
      .then((res) => {
        currentNumber(res.currentNum);
        // console.log("getCurrentCount", res);
      })
      .catch((err) => {
        // console.log(err.message);
      });
    getTotalCount(newId)
      .then((res) => {
        // console.log("getTotalCount");
        setTotalNumber(res);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };
  return (
    <div className={classes.root}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.profile}>
        <div className={classes.heading}>
          <div>
            <IconButton
              component="span"
              onClick={() => {
                // setOpenDrawer(false);
                handleCloseHomeDrawer();
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <p>{newId.split("_")[2]}</p>
            {/* <SettingsIcon  onClick={()=>{toggleDrawer()}}/> */}
          </div>
        </div>
        <Box className={classes.userButton}>
          <div style={{ paddingTop: "13px" }}>
            {/* <Divider /> */}
            <Button onClick={() => history.push(`/device-setting/${props.id}`)}>
              Manage Queue
            </Button>
            {/* <Divider /> */}
            <div width="100%">
              <Button
                onClick={() => history.push(`message-details/${props.id}`)}
              >
                Send Emergency Message
              </Button>
            </div>
            <div width="100%"></div>
            <Button onClick={() => history.push(`/custom-details/${props.id}`)}>
              Custom Form
            </Button>
          </div>
          {/* <Divider /> */}
          <div width="100%">
            <Button
              // size="large"
              onClick={() =>            
                  history.push(`/device-setting`, { customProp: newId })
              }
              // startIcon={<ReactSVG src={wifiline} />}
            >
              Wifi Settings
            </Button>
          </div>
          {/* <Divider /> */}
          <div width="100%">
            <Button
              // size="large"
              onClick={() => history.push(`/bill-board`)}
              // startIcon={<MonitorIcon />}
            >
              Bill Board
            </Button>
            {/* <Divider /> */}
          </div>
        </Box>
      </Grid>
    </div>
  );
};

export default HomePage;
