// import * as React from "react";
import React, { useState } from "react";
import {
  CircularProgress,
  Fab,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import useStyles from "../../assests/css/styles";
import { getAnalyticList, getCurrentCount } from "../../api";
import moment from "moment";
import MQTTConnection from "../../services/MqttConnection";
import MQTTConnectionList from "../../services/MqttConnectionList";
import MqttDeleteConnection from "../../services/MqttDeleteConnection";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import { Header } from "../../component/HeaderWithoutBack";
import { Header } from "./Header";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AnalyticsItem from "../../pages/AnalyticsItem";

const AnalyticsPage = (props) => {
  // console.log("props", props.location.state.pathUrl);

  const [minDateCalender, setMinDateCalender] = useState("");
  const [value, setValue] = useState({ minDateCalender });
  const classes = useStyles();
  const location = useLocation();
  let { id } = useParams();
  const device = atob(id);
  const ref = React.useRef(null);
  const count = location.state && location.state.count;
  const [isLoading, setIsLoading] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [newList, setNewList] = React.useState({});
  const [currentNumber, setCurrentNumber] = React.useState(0);
  const [mqttClient, setMqttClient] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [products, setProducts] = useState(list);
  const [searchInput, setSearchInput] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const countChange = list && list.length && list.map((item) => item.Count);

  const [dateValue, setDateValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  React.useEffect(() => {
    getAnalyticListHandler();
  }, []);
  const getAnalyticListHandler = () => {
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    setIsLoading(true);
    getAnalyticList(device, `${todayDate}`)
      .then((res) => {
        if (res.status === 200) {
          console.log('analyticres', res.data.Data)
          setIsLoading(false);
          setList(res.data.Data);
          setProducts(res.data.Data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getAnalyticListHandlerWIthoutReload = () => {
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    getAnalyticList(device, `${todayDate}`)
      .then((res) => {
        if (res.status === 200) {
          setList(res.data.Data);
          setProducts(res.data.Data);
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    if (Object.keys(newList).length > 0 && newList.constructor === Object) {
      const data = [...list, newList];
      setList(data.sort((a, b) => b - a));
    }
  }, [newList]);

  React.useEffect(() => {
    if (count) {
      setCurrentNumber(Number(count));
    } else {
      getCurrentCount(device)
        .then((res) => {
          setCurrentNumber(res.currentNum);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  const handleCountChange = (change) => {
    const data = {
      deviceName: device,
    };
    if (change === "increase") {
      const number = Number(currentNumber) + 1;
      setCurrentNumber(number);
      data.count = number;
    }
    if (change === "decrease") {
      const number =
        Number(currentNumber) > 0
          ? Number(currentNumber) - 1
          : Number(currentNumber);
      setCurrentNumber(number);
      data.count = number;
    }

    const newIdnewId = {
      Device_Name: `${device}_Device`,
      Date_Time: moment(new Date()).format("YYYY-MM-DD/HH:mm:ss:SSSS"),
      Count: data.count.toString(),
    };
    mqttClient.publish(`${device}_Device`, JSON.stringify(newIdnewId));
  };

  const handleChangeDate = (newValue) => {
    setDateValue(newValue.format("YYYY-MM-DD"));
    setIsLoading(true);
    setSelectedDate(newValue.format("YYYY-MM-DD"));
    getAnalyticList(device, newValue.format("YYYY-MM-DD"))
      .then((res) => {
        if (res.status === 200) {
          setList(res.data.Data);
          setProducts(res.data.Data);
          setIsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  React.useEffect(() => {
    if (searchInput && searchInput.length > 0) {
      const data = list.filter((item) => {
        return item.Name.toLowerCase().includes(searchInput.toLowerCase());
      });
      setProducts(data);
      if (searchInput === "") {
        setProducts(list);
      }
    } else {
      getAnalyticList(device, dateValue)
        .then((res) => {
          if (res.status === 200) {
            setProducts(res.data.Data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [searchInput]);
  const removeUserHandler = () => {
    getAnalyticListHandlerWIthoutReload();
  };
  //   console.log("Subscribedtodata",products)

  function handleExportToExcel() {
    const OUTPUT = products.map((item) => {
      const dynamicObject = {};
      dynamicObject["Count"] = item.Count;
      const date = new Date(item.Date_Time);
      const options = {
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      const formattedDate = date.toLocaleString("en-US", options);
      dynamicObject["Date_Time"] = formattedDate;

      // Loop through keys in Form_Data
      for (const key in item.Form_Data) {
        dynamicObject[key] = item.Form_Data[key];
      }

      return dynamicObject;
    });
    const ws = XLSX.utils.json_to_sheet(OUTPUT);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "exported-data.xlsx");
  }

  return (
    <div
      style={{
        height: "100vh",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <MQTTConnection
        totalCountTopic={`${device}_App`}
        setTotalNumber={setNewList}
        currentCountTopic={`${device}_Device`}
        setCurrentNumber={setCurrentNumber}
        setMqttClient={setMqttClient}
      />
      <MQTTConnectionList
        totalCountTopic={`${device}_App`}
        // setTotalNumber={setNewList}
        // currentCountTopic={`${device}_Device`}
        // setCurrentNumber={setCurrentNumber}
        // setMqttClient={setMqttClient}
        setProducts={setProducts}
        setList={setList}
      />
      <MqttDeleteConnection
        totalCountTopic={`${device}_Delete`}
        removeUserHandler={removeUserHandler}
      />
      <Header />
      {!isLoading ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1.2rem",
          }}
        >
          <div className={classes.customModel}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {" "}
              <DesktopDatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                InputProps={{
                  disableUnderline: true,
                }}
                className={classes.positionMobile}
                PopperProps={{ placement: "bottom-end" }}
                minDate={minDateCalender}
                inputFormat="DD MMM YYYY"
                value={dateValue}
                onChange={handleChangeDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    readOnly={true}
                    onClick={(e) => setOpen(true)}
                    editable={false}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  />
                )}
                // popperPlacement="bottom-start"
              />
            </LocalizationProvider>
            <div className={classes.customSearchGroup}>
              <span className={classes.customSearchbtn}>
                <input
                  className={classes.inputModel}
                  type="text"
                  placeholder="Search"
                  onChange={handleSearchChange}
                  value={searchInput}
                />
              </span>
              {products &&
              products.length &&
              products[0].Date_Time.split("/")[0] == dateValue ? (
                <Button
                  className={classes.commonBtn}
                  style={{ width: "100%" }}
                  variant="contained"
                  type="button"
                  onClick={handleExportToExcel}
                  startIcon={<FileDownloadOutlinedIcon />}
                >
                  Download Analytics
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>

          {products &&
          products.length &&
          products[0].Date_Time.split("/")[0] == dateValue ? (
            <>
              <AnalyticsItem
                elemRef={ref}
                data={products}
                currentNumber={currentNumber}
              />
              <div className={classes.currentCountChange}>
                {countChange[0] > 0 &&
                selectedDate === moment(new Date()).format("YYYY-MM-DD") ? (
                  <>
                    <Grid item>
                      <Fab
                        color="primary"
                        aria-label="add"
                        disabled={currentNumber <= 1}
                        onClick={() => handleCountChange("decrease")}
                      >
                        <RemoveIcon color="white" />
                      </Fab>
                    </Grid>
                    <Grid item>
                      <Fab
                        color="primary"
                        aria-label="add"
                        disabled={currentNumber >= list.length}
                        onClick={() => handleCountChange("increase")}
                      >
                        <AddIcon />
                      </Fab>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <p
              style={{
                flex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {searchInput
                ? "No one with this name assigned in the queue."
                : "No one is assigned in queue."}
            </p>
          )}
        </div>
      ) : (
        <div
          style={{
            flex: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{ position: "initial" }}
            className={classes.loader}
            color="primary"
          />
        </div>
      )}
    </div>
  );
};

export default AnalyticsPage;
